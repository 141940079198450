import React from "react";

export default function Header() {
  return (
    <header id="header" className="w-full max-w-7xl mx-auto px-4 border-b">
      <div className="flex justify-center items-center py-6">
        <a href="/">
          <span className="sr-only">Codia</span>
          <img className="h-6 w-auto sm:h-8" src="/images/logo.svg" alt="" />
        </a>
      </div>
    </header>
  );
}
