import { FaLinkedin, FaGithub } from "react-icons/fa";

export default [
  {
    name: "LinkedIn",
    href: "https://linkedin.com/company/codia-software",
    icon: FaLinkedin,
  },
  {
    name: "GitHub",
    href: "https://github.com/Codia-Software",
    icon: FaGithub,
  },
];
